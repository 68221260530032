import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import Home from "./views/Home";
import ThankYou from "./views/ThankYou";

import "./styles/global-styles.scss";

function App() {
  const location = useLocation();

  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", appHeight);
    appHeight();
    return () => window.removeEventListener("resize", appHeight);
  }, []);

  return (
    <div className="App">
      <div className="outer-wrapper">
        <div className="section-wrapper">
          <img src="./KIIMI-LOGO.png" className="logo" alt="KIIMI" />
          <h3>Debut Live Show</h3>
        </div>
        <div className="section-wrapper">
          <h1>01.11.2023</h1>
          <h2>
            The Social
            <br />
            5 Little Portland St
            <br />
            W1W 7JD
            <br />
            London
          </h2>
        </div>
        <Routes location={location} key={location.pathname}>
          <Route element={<Home key={"home"} />} path="/" />
          <Route element={<ThankYou key={"thankYou"} />} path="/thank-you" />
        </Routes>
      </div>
    </div>
  );
}

export default App;
