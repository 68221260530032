const Home = () => {
  return (
    <>
      <div className="section-wrapper">
        <h2>Sign up for exclusive access to pre-sale tickets</h2>
      <div id="mc_embed_shell" className="mailchimp__wrapper">
        <div id="mc_embed_signup">
          <form
            action="https://gmail.us21.list-manage.com/subscribe/post?u=e0a7fbff6331a0dfcdaa4a528&amp;id=1a704272fd&amp;f_id=008854e1f0"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_self"
            noValidate=""
          >
            <div id="mc_embed_signup_scroll">
              <h2>ENTER EMAIL ADDRESS</h2>
              <div className="mc-field-group">
                <input
                  type="email"
                  name="EMAIL"
                  className="required email"
                  id="mce-EMAIL"
                  required=""
                  // value=""
                />
              </div>
              <div id="mce-responses" className="clear">
                <div
                  className="response"
                  id="mce-error-response"
                  style={{ display: "none" }}
                ></div>
                <div
                  className="response"
                  id="mce-success-response"
                  style={{ display: "none" }}
                ></div>
              </div>
              <div
                aria-hidden="true"
                style={{ position: "absolute", left: "-5000px" }}
              >
                <input
                  type="text"
                  name="b_e0a7fbff6331a0dfcdaa4a528_1a704272fd"
                  tabIndex="-1"
                  // value=""
                />
              </div>
              <div className="clear">
                <input
                  type="submit"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className="button"
                  value="Subscribe"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      </div>
    </>
  );
};
export default Home;
