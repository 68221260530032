const ThankYou = () => {

  return (
    <>
       <div className="section-wrapper">
        <h2>Thank you for Subscribing</h2>
      </div>
    </>
  );
};
export default ThankYou;
